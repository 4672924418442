import { WILLS_FAKE_DISCOUNT } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

import { WILLS_V3_6_CONTROL_FLOW } from './useWillsFunnelFlow'

export enum WillsFakeDiscountVariants {
  OFF = 'off',
  TREATMENT_1 = 'treatment_1',
  TREATMENT_2 = 'treatment_2',
  TREATMENT_3 = 'treatment_3',
}

export const useWillsFakeDiscountExperiment = (flow: string | undefined) => {
  const { isLoading, variation } = useDecisionForFlag({
    name: WILLS_FAKE_DISCOUNT.EXPERIMENT_KEY,
    fallbackVariation: WILLS_FAKE_DISCOUNT.VARIATIONS.OFF,
  })

  if (flow !== WILLS_V3_6_CONTROL_FLOW) {
    return {
      isLoading: false,
      isTreatment: false,
      variation: null,
    }
  }

  return {
    isLoading,
    isTreatment:
      variation === WILLS_FAKE_DISCOUNT.VARIATIONS.TREATMENT_1 ||
      variation === WILLS_FAKE_DISCOUNT.VARIATIONS.TREATMENT_2 ||
      variation === WILLS_FAKE_DISCOUNT.VARIATIONS.TREATMENT_3,
    variation: variation as WillsFakeDiscountVariants,
  }
}
