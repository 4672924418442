import { WILLS_PLAN_PRICE } from '@/constants'

import { WillsFakeDiscountVariants } from '@/hooks/features/useWillsFakeDiscountExperiment'

export const getPlanPrice = (
  variation: WillsFakeDiscountVariants | null,
  isTreatment: boolean
) => {
  const fullEstatePlanPrice = WILLS_PLAN_PRICE.fullEstatePlanPrice
  const basicWillPlanPrice = WILLS_PLAN_PRICE.basicWillPlanPrice

  if (!isTreatment) {
    return {
      willPrice: basicWillPlanPrice,
      trustPrice: fullEstatePlanPrice,
      willOriginalPrice: undefined,
      trustOriginalPrice: undefined,
      willDiscount: undefined,
      trustDiscount: undefined,
    }
  }

  if (
    variation === WillsFakeDiscountVariants.TREATMENT_1 ||
    variation === WillsFakeDiscountVariants.TREATMENT_2
  ) {
    return {
      willPrice: basicWillPlanPrice,
      trustPrice: fullEstatePlanPrice,
      willOriginalPrice: undefined,
      trustOriginalPrice: 449,
      willDiscount: undefined,
      trustDiscount: 100,
    }
  }

  if (variation === WillsFakeDiscountVariants.TREATMENT_3) {
    return {
      willPrice: basicWillPlanPrice,
      trustPrice: fullEstatePlanPrice,
      willOriginalPrice: 189,
      trustOriginalPrice: 449,
      willDiscount: 40,
      trustDiscount: 100,
    }
  }

  return {
    willPrice: basicWillPlanPrice,
    trustPrice: fullEstatePlanPrice,
    willOriginalPrice: undefined,
    trustOriginalPrice: undefined,
    willDiscount: undefined,
    trustDiscount: undefined,
  }
}
