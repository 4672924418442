import React from 'react'

import dynamic from 'next/dynamic'

const IconV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Icon),
  { ssr: false }
)

export const LimitedTimeOffer = ({ discount }: { discount: number }) => {
  return (
    <div
      className={`mb-8 flex h-[30px] w-max items-center rounded-[32px] bg-[#F5F8FC] px-[12px] py-[6px]`}
    >
      <IconV2
        name={'schedule'}
        sx={{ color: 'black' }}
        baseClassName="material-icons-outlined"
      />
      <p className="ml-1 text-xs font-medium">
        Limited time offer: ${discount} off
      </p>
    </div>
  )
}
